import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import English from "./locales/eng.json";

export const LANGUAGES = {
  eng: "English",
};

export const DEFAULT_LANG = "eng";

const resources = {
  eng: {
    translation: English,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANG,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
