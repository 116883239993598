import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllForcesTypes } from "../../orgChart/dataCustom";
import GroupChart from "../../orgChart/groupChart";
import ChartDatePicker from "./chartDatePicker";
import ChartDateScroll from "./chartDateScroll";
import ChartSortBy from "./chartSortBy";
import ChartTabs from "./chartTabs";
import { START_DATE } from "./date";
import "./orgChart.css";

const OrgChart = () => {
  const { t } = useTranslation("translation");
  const [typeId, setTypeId] = useState("");
  const [activeSortBy, setActiveSortBy] = useState([]);
  const [activeDate, setActiveDate] = useState(new Date(START_DATE));
  const [forceTypes, setForceTypes] = useState([]);

  useEffect(() => {
    (async () => {
      const allForceTypes = await getAllForcesTypes();
      setForceTypes([
        {
          id: "",
          name: "All",
        },
        ...allForceTypes.military_forces_types,
      ]);
    })();
  }, []);
  const filterOptions = {
    type: typeId,
    sortByOptions: activeSortBy,
    date: activeDate,
  };

  const [zoomOut, setZoomOut] = useState(false);
  const handleZoom = () => setZoomOut(!zoomOut);
  const zoomCN = zoomOut ? "orgChart--zoomOut" : "";

  return (
    <div className={`orgChart ${zoomCN}`}>
      <div className="orgChart__tabs">
        <ChartTabs list={forceTypes} typeId={typeId} setTypeId={setTypeId} />
      </div>

      <div className="orgChart__chart">
        <ChartDateScroll
          setActiveDate={setActiveDate}
          activeDate={activeDate}
        />
        <ChartSortBy
          activeSortBy={activeSortBy}
          setActiveSortBy={setActiveSortBy}
        />
        <ChartDatePicker
          activeDate={activeDate}
          setActiveDate={setActiveDate}
        />

        <GroupChart filterOptions={filterOptions} zoomOut={zoomOut} />

        <div className="chartZoomIcon" onClick={handleZoom}>
          <img
            className="chartZoomIcon__image"
            src="/images/commons/icon-zoom.png"
            alt="zoom"
          />
        </div>
      </div>

      <div className="orgChart__footer bgDark">
        <img
          className="orgChart__footer--icon"
          src="/images/home/logo-in-chart.png"
          alt="logo"
        />
        <div>{t("home.body.orgChart.footer")}</div>
      </div>
    </div>
  );
};

export default OrgChart;
