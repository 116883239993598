import React from "react";
import { v4 as uuidv4 } from "uuid";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import { ORGANISATIONS_SORT_BY_OPTIONS } from "../../../../commons/data/allData";
import ChartSortByItem from "./chartSortByItem";
import "./chartSortBy.css";

const ChartSortBy = ({ activeSortBy, setActiveSortBy }) => {
  const { t } = useTranslation("translation");

  return (
    <div className="chartSortBy__wrapper">
      <div className="bgWhiteFullAbsolute"></div>

      <div className="chartSortBy inputBgBorder">
        <div className="chartSortBy__main">
          <div className="chartSortBy__main--text">
            {t("home.body.orgChart.sortBy")}
          </div>
          <img
            className="chartSortBy__main--icon"
            src="/images/commons/icon-arrow-down.png"
            alt="select"
          />
        </div>

        <div className="chartSortBy__menu">
          <div className="chartSortBy__menu--options">
            {map(ORGANISATIONS_SORT_BY_OPTIONS, (option) => (
              <ChartSortByItem
                key={uuidv4()}
                text={option}
                selectedOptions={activeSortBy}
                setSelectedOptions={setActiveSortBy}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartSortBy;
