import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { map } from "lodash";

import { PUBLICATIONS } from "../../../commons/data/allData";
import Publication from "../../commons/publication";
import "./publications.css";

const Publications = () => {
  const { t } = useTranslation("translation");

  return (
    <div className="sectionContainer">
      <div className="sectionName">{t("home.body.publications.name")}</div>

      {map(
        PUBLICATIONS,
        ({ imgSrc, name, description, author, date, active }) => {
          return (
            <Publication
              key={uuidv4()}
              active={active}
              imgSrc={imgSrc}
              name={name}
              description={description}
              author={author}
              date={date}
            />
          );
        },
      )}
    </div>
  );
};

export default Publications;
