import React from "react";

import "./newsElement.css";

const NewsElement = ({ imgSrc, newsName, description, noTextWrap }) => {
  const textWrap = noTextWrap ? "noTextWrap" : "";
  return (
    <div className="newsElementContainer">
      <img className="image" src={imgSrc} alt="icon" />
      <span className={`name ${textWrap}`}>{newsName}</span>
      <span className="description">{description}</span>
    </div>
  );
};

export default NewsElement;
