import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { map } from "lodash";

import "./dateDropdown.css";

const DateDropdown = ({ setDuration }) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState("Daily");

  const dayMonthYear = ["Daily", "Monthly", "Yearly"];

  const handleClickMenu = () => setOpen(!open);
  const changeDate = (data) => {
    setDate(data);
    setDuration(data);
  };

  return (
    <div className="dropdownDate bgGreenColorWhite" onClick={handleClickMenu}>
      <div className="date__show">
        <div className="activeDate">{date}</div>
        <img
          className="date__arrow"
          src="/images/commons/white-arrow-down.png"
          alt="choose language"
        />
      </div>

      {open && (
        <div className="date__menu">
          {map(dayMonthYear, (data) => (
            <div
              onClick={() => changeDate(data)}
              key={uuidv4()}
              className="date__option"
            >
              {data}{" "}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DateDropdown;
