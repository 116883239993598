import React from "react";
import "./navbarItem.css";

const NavbarItem = ({ label, href, isActive }) => {
  const activeCN = isActive ? "navbarItem--active" : "";

  return <div className={`navbarItem ${activeCN}`}>{label}</div>;
};

export default NavbarItem;
