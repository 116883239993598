import React from "react";
import { v4 as uuidv4 } from "uuid";
import { map } from "lodash";
import { useTranslation } from "react-i18next";

import { DOCUMENTS } from "../../../commons/data/allData";
import DocumentCard from "../../commons/documentCard";
import "./documentCards.css";

const DocumentCards = () => {
  const { t } = useTranslation("translation");

  return (
    <div className="documentCards">
      {map(DOCUMENTS, (doc) => {
        return (
          <DocumentCard
            {...doc}
            key={uuidv4()}
            name={t(`home.body.documentCards.${doc.name}`)}
          />
        );
      })}
    </div>
  );
};

export default DocumentCards;
