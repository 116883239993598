import React, { useState } from "react";
import i18n from "i18next";
import { v4 as uuidv4 } from "uuid";
import { keys, map } from "lodash";
import { DEFAULT_LANG, LANGUAGES } from "../../../i18n/i18n";
import "./languageDropdown.css";

const LanguageDropdown = () => {
  const [open, setOpen] = useState(false);
  const [lang, setLang] = useState(DEFAULT_LANG);
  const shortLangs = keys(LANGUAGES);

  const handleClickMenu = () => setOpen(!open);
  const changeLanguage = (shortLang) => {
    i18n.changeLanguage(shortLang);
    setLang(shortLang);
  };

  return (
    <div
      className="languageDropdown bgGreenColorWhite"
      onClick={handleClickMenu}
    >
      <div className="languageDropdown__show">
        <div className="activeLanguage">{LANGUAGES[lang]}</div>
        <img
          className="languageDropdown__arrow"
          src="/images/commons/arrow-down.png"
          alt="choose language"
        />
      </div>

      {open && (
        <div className="languageDropdown__menu">
          {map(shortLangs, (shortLang) => (
            <div
              key={uuidv4()}
              className="languageDropdown__option"
              onClick={() => changeLanguage(shortLang)}
            >
              {LANGUAGES[shortLang]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
