import React from "react";

const CardItemCenter = ({ data }) => {
  return (
    <div
      id={`card-${data.id}`}
      className="cardWrap"
      style={{
        width: `${data?.width || 150}px`,
        height: `${data?.height || 105}px`,
      }}
    >
      <div className="cardContent">
        <div className="cardContent__name">{data?.name}</div>
      </div>
    </div>
  );
};
export default CardItemCenter;
