import React, { useState } from "react";
import { map } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { NAVBAR_ITEMS } from "../../commons/data/allData";
import BodyWrapper from "../commons/bodyWrapper";
import NavbarItem from "./navbarItem";
import "./navBar.css";
import { useTranslation } from "react-i18next";

const NavBar = () => {
  const [activeId, setActiveId] = useState(0);
  const { t } = useTranslation("translation");

  return (
    <div className="navBarWrapper bgGreenColorWhite">
      <BodyWrapper>
        <div className="navBarWrapper__list">
          {map(NAVBAR_ITEMS, (item, id) => {
            const isActive = activeId === id;
            return (
              <div key={uuidv4()} onClick={() => setActiveId(id)}>
                <NavbarItem
                  {...item}
                  label={t(`home.navbar.${item.key}`)}
                  isActive={isActive}
                />
              </div>
            );
          })}
        </div>
      </BodyWrapper>
    </div>
  );
};

export default NavBar;
