import React from "react";

const CardLineItem = ({ title, value }) => {
  return (
    <div className="cardItem">
      <div className="cardItemTitle">{`${title}:`}</div>
      <div className="cardItemValue">{value || "-"}</div>
    </div>
  );
};

export default CardLineItem;
