import React from "react";
import { map } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import { NEWS_IMAGES } from "../../../commons/data/allData";
import "./behaviorInvestigated.css";
import NewsElement from "../../commons/newsElement";

const BehaviorInvestigated = () => {
  const { t } = useTranslation("translation");
  return (
    <div className="sectionContainer">
      <div className="sectionName">{t("home.body.behavior.name")}</div>
      <div className="boardContainer">
        <div className="seeAllContainer">
          <span>{t("home.body.behavior.seeAll")}</span>
          <img
            className="nextArrow"
            src="/images/commons/next-arrow.png"
            alt="behavior"
          />
        </div>
        <div className="news">
          {map(NEWS_IMAGES, ({ imgSrc, name, description }) => {
            return (
              <NewsElement
                key={uuidv4()}
                imgSrc={imgSrc}
                newsName={name}
                description={description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BehaviorInvestigated;
