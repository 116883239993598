import React from "react";
import CardLineItem from "./cardLineItem";

const CardItemDetail = ({ data = {}, x, y }) => {
  return (
    <div className="cardDetail bgDark" id="template">
      <img
        className="cardDetail__avatar"
        src="/images/avatar-default.png"
        alt="avatar"
      />
      <div className="nameAndRole">
        <div>{data.name}</div>
        <div>{data.role}</div>
      </div>
      <div className="cardDetail__content">
        <CardLineItem title={"Number"} value={data.number} />
        <CardLineItem title={"Previous Name"} value={data.previous_name} />
        <CardLineItem
          title={"Military Forces Type"}
          value={data.military_forces_type}
        />
        <CardLineItem title={"Upper Unit"} value={data.upper_unit_name} />
        <CardLineItem title={"Lower Unit"} value={data.lower_unit} />
        <CardLineItem title={"Location"} value={data.location} />
        <CardLineItem title={"Territory"} value={data.territory} />
        <CardLineItem title={"Date of creation"} value={data.creation_date} />
        <CardLineItem
          title={"Date of termination"}
          value={data.termination_date}
        />
      </div>
    </div>
  );
};

export default CardItemDetail;
