import React, { useEffect, useState } from "react";
import Slider from "rc-slider";
import "./rc-slider.css";
import "./chartDateScroll.css";
import "tippy.js/themes/light.css";
import { tippy } from "@tippyjs/react";
import moment from "moment";
import { FORMAT_DATE, START_DATE } from "../date";

let currentTippy;
const ChartDateScroll = ({ setActiveDate, activeDate }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const target = document.querySelector(".rc-slider-handle");
    const template = document.getElementById("cs-tooltip");
    currentTippy = tippy(target, {
      placement: "bottom",
      arrow: true,
      content: template.innerHTML,
      allowHTML: true,
      showOnLoad: true,
      theme: "dateScrollToollip",
    });
  }, []);
  const handleChange = (value) => {
    const newValue = Math.min(Math.max(value, 0), 100);
    setValue(newValue);
    const startDate = new Date(START_DATE).getTime();
    const endDate = new Date().getTime();
    const timeSpan = endDate - startDate;
    const currentDate = startDate + (timeSpan * newValue) / 100;
    setActiveDate(new Date(currentDate));
    document.getElementById("cs-tooltip-inner").innerHTML = moment(
      new Date(currentDate),
    ).format(FORMAT_DATE);
    const template = document.getElementById("cs-tooltip");
    currentTippy.setContent(template.innerHTML);
  };

  return (
    <div>
      <div id="cs-tooltip" style={{ display: "none" }}>
        <div className="cs-tooltip-inner" id="cs-tooltip-inner">
          {moment(activeDate).format(FORMAT_DATE)}
        </div>
      </div>
      <div className="leftArrow">
        <svg
          width="4"
          transform="scale(-1, 1)"
          height="8"
          viewBox="0 0 4 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0.412415L7.15256e-07 7.41241H1V6.41241H2V5.41241H3L3 4.41241H4V3.41241H3V2.41241H2L2 1.41241L1 1.41241V0.412415L0 0.412415Z"
            fill="#454833"
          />
        </svg>
      </div>
      <div>
        <Slider min={-7} max={107} onChange={handleChange} value={value} />
      </div>
      <div className="rightArrow">
        <svg
          width="4"
          height="8"
          viewBox="0 0 4 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0.412415L7.15256e-07 7.41241H1V6.41241H2V5.41241H3L3 4.41241H4V3.41241H3V2.41241H2L2 1.41241L1 1.41241V0.412415L0 0.412415Z"
            fill="#454833"
          />
        </svg>
      </div>
    </div>
  );
};

export default ChartDateScroll;
