import React from "react";
import { v4 as uuidv4 } from "uuid";
import { map, padStart, split, toString } from "lodash";
import "./quantityLine.css";

const QuantityLine = ({ quantity }) => {
  const quantityStr = padStart(toString(quantity), 5, "0");
  const quantityCharacters = split(quantityStr, "");

  return (
    <div className="quantityLine bgGreenColorWhite">
      {map(quantityCharacters, (char) => (
        <div key={uuidv4()} className="quantityLine__character">
          <div>{char}</div>
        </div>
      ))}
    </div>
  );
};

export default QuantityLine;
