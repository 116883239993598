import React from "react";
import BodyWrapper from "../commons/bodyWrapper";
import DocumentCards from "./documentCards";
import Welcome from "./welcome";
import OrgChart from "./orgChart";
import BehaviorInvestigated from "./behaviorInvestigated";
import Violations from "./violations";
import Publications from "./publications";
import LineGraph from "./lineGraph";
import SignupForm from "./signupForm";
import "./body.css";

const Body = () => {
  return (
    <BodyWrapper>
      <div className="bodyContent">
        <Welcome />
        <DocumentCards />
        <OrgChart />
        <BehaviorInvestigated />
        <Violations />
        <Publications />
        <LineGraph />
        <SignupForm />
      </div>
    </BodyWrapper>
  );
};

export default Body;
