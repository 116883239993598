const headers = new Headers();
headers.append(
  "Authorization",
  "Bearer tkn_live_YPDnWdEzKHyQREaymFp8sxdCFG1VCq",
);
headers.append("Content-Type", "application/json");

const requestOptions = {
  method: "GET",
  headers,
  redirect: "follow",
};

export const getAllForcesTypes = async () => {
  try {
    const response = await fetch(
      `https://rgcdashboard.org/api/v1/rgc/military_forces_types?page=1&per_page=10`,
      requestOptions,
    );
    const result = await response.text();
    return JSON.parse(result);
  } catch (error) {
    console.log(error);
  }
};

export const getChartData = async ({
  date = "04-22-1979",
  role = "",
  military_forces_type_id = "",
  language = "en",
}) => {
  try {
    const response = await fetch(
      `https://rgcdashboard.org/api/v1/rgc/rgc_structure?date=${date}&role=${role}&military_forces_type_id=${military_forces_type_id}&language=${language}`,
      requestOptions,
    );
    const result = await response.text();
    return JSON.parse(result);
  } catch (error) {
    console.log(error);
  }
};

export const MILITARY_FORCE_TYPES = [
  {
    id: "",
    name: "All",
  },
  {
    id: "65def8966718f86ec9734696",
    name: "Ground",
  },
  {
    id: "65def8d86718f86ec9734699",
    name: "Aerospace",
  },
  {
    id: "65def94b6718f86ec973469f",
    name: "Quds",
  },
  {
    id: "65def94b6718f86ec973469ff",
    name: "Navy",
  },
  {
    id: "65def94b6718f86ec973469fa",
    name: "Mohammad Rasool Allah",
  },
  {
    id: "65def94b6718f86ec973469fv",
    name: "Subordinate of Supreme Leader",
  },
];

export const dataCustom = {
  units: [
    {
      id: "6506f8e76718f86efa48270e",
      name: "Command of Quds Force",
      previous_name: "",
      number: "U76392",
      upper_unit_id: "65340d2d6718f85f4dfc87a9",
      upper_unit_name: "Islamic revolution guards corps",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "650ac37a6718f86efa482cc6",
      name: "Ministry",
      previous_name: "",
      number: "U89315",
      upper_unit_id: "65340d2d6718f85f4dfc87a9",
      upper_unit_name: "Islamic revolution guards corps",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "11-1982",
      termination_date: "09-1989",
    },
    {
      id: "651527af6718f87c0e83e320",
      name: "Command of Karbala Garrison",
      previous_name: "South Karbala Regional Headquarters",
      number: "U53820",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65152d8a6718f87c0a83e397",
      name: "Badr 9th Division",
      previous_name: "9 Badr Brigade, Badr's Mujahideen Brigade",
      number: "U41784",
      upper_unit_id: "6533f8146718f85f4ffc86a2",
      upper_unit_name: "Ground Forces",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "651bea036718f852b54eb677",
      name: "Command of the Navy force",
      previous_name: "",
      number: "U74337",
      upper_unit_id: "65340d2d6718f85f4dfc87a9",
      upper_unit_name: "Islamic revolution guards corps",
      // military_forces_type: null,
      military_forces_type: "Navy",
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "653132586718f8d05ee67c0b",
      name: "IRGC is the holy Abu al-Fadl division of Luristan province",
      previous_name: "",
      number: "U83918",
      upper_unit_id: "651527af6718f87c0e83e320",
      upper_unit_name: "Command of Karbala Garrison",
      military_forces_type: "Ground Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "653137156718f8d05be67c1e",
      name: "Cooperation Foundation",
      previous_name: "",
      number: "U55622",
      upper_unit_id: "65340d2d6718f85f4dfc87a9",
      upper_unit_name: "Islamic revolution guards corps",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "10-21-1986",
      termination_date: null,
    },
    {
      id: "653176fb6718f8d05be67c6d",
      name: "Unit of Freedom Movements.",
      previous_name: "",
      number: "U44383",
      upper_unit_id: "65340d2d6718f85f4dfc87a9",
      upper_unit_name: "Islamic revolution guards corps",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65328b936718f85f4ffc84c1",
      name: "Operations Unit",
      previous_name: "",
      number: "U54649",
      upper_unit_id: "65340d2d6718f85f4dfc87a9",
      upper_unit_name: "Islamic revolution guards corps",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "6533f8146718f85f4ffc86a2",
      name: "Ground Forces",
      previous_name: "",
      number: "U39605",
      upper_unit_id: "65340d2d6718f85f4dfc87a9",
      upper_unit_name: "Islamic revolution guards corps",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65340d2d6718f85f4dfc87a9",
      name: "Islamic revolution guards corps",
      previous_name: "Previous",
      number: "U49411",
      upper_unit_id: "",
      upper_unit_name: null,
      // military_forces_type: null,
      military_forces_type: "Ground Force",
      // role: null,
      role: "operational",
      location: "Earth",
      territory: "Land",
      creation_date: "04-22-1979",
      termination_date: "01-22-2024",
    },
    {
      id: "654430506718f89ebd1d193b",
      name: "Congress Martyrs Headquarters",
      previous_name: "",
      number: "U71164",
      upper_unit_id: "6533f8146718f85f4ffc86a2",
      upper_unit_name: "Ground Forces",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "654443ea6718f89ebd1d19db",
      name: "Research and Information Unit",
      previous_name: "",
      number: "U85905",
      upper_unit_id: "654d68a66718f830615c82a4",
      upper_unit_name: "Command Council of Kermanshah Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1969",
      termination_date: null,
    },
    {
      id: "6549924a6718f830615c806a",
      name: "Coordination Council.",
      previous_name: "",
      number: "U11471",
      upper_unit_id: "65340d2d6718f85f4dfc87a9",
      upper_unit_name: "Islamic revolution guards corps",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6549b9996718f830615c80b3",
      name: "The Supreme Command Council.",
      previous_name: "",
      number: "U55976",
      upper_unit_id: "65340d2d6718f85f4dfc87a9",
      upper_unit_name: "Islamic revolution guards corps",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654afe916718f830635c8139",
      name: "Public Relations",
      previous_name: "",
      number: "U36307",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654b00f66718f830635c815c",
      name: "Training unit",
      previous_name: "",
      number: "U35334",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654b08806718f830635c8184",
      name: "Financial and Administrative unit",
      previous_name: "",
      number: "U87910",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654b0a7a6718f830615c81a0",
      name: "procurement unit",
      previous_name: "",
      number: "U38809",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654c3ebb6718f830615c8220",
      name: "Unit of Research and Information",
      previous_name: "",
      number: "U70182",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d65c16718f830615c825c",
      name: "Markazi Province Command Council",
      previous_name: "",
      number: "U54320",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d66836718f830635c8276",
      name: "West Azarbaijan Province Command Council",
      previous_name: "",
      number: "U60345",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d66e46718f830615c827b",
      name: "Command Council of East Azarbaijan Province",
      previous_name: "",
      number: "U09006",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d67546718f830615c8289",
      name: "Kurdistan Provincial Command Council",
      previous_name: "",
      number: "U17866",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d67cc6718f830635c828c",
      name: "Zanjan Province Command Council",
      previous_name: "",
      number: "U98938",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d68a66718f830615c82a4",
      name: "Command Council of Kermanshah Province",
      previous_name: "",
      number: "U05833",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d69296718f830615c82b8",
      name: "Khuzestan Province Command Council",
      previous_name: "",
      number: "U24788",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d698d6718f830635c82a5",
      name: "Lorestan Province Command Council",
      previous_name: "",
      number: "U44831",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d6a096718f830635c82ba",
      name: "Ilam Province Command Council",
      previous_name: "",
      number: "U74052",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d6a8e6718f830615c82cf",
      name: "Command Council of Chaharmahal and Bakhtiari province",
      previous_name: "",
      number: "U22406",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d6b1c6718f830615c82d8",
      name: "Command Council of Kohgiluyeh and Boyer Ahmad province",
      previous_name: "",
      number: "U62511",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d6bc56718f830615c82e9",
      name: "Command Council of Yazd Province",
      previous_name: "",
      number: "U49718",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d6c286718f830615c82f8",
      name: "Kerman Province Command Council",
      previous_name: "",
      number: "U13844",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d6c6c6718f830615c8309",
      name: "Command Council of Sistan and Baluchistan Province",
      previous_name: "",
      number: "U53873",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d6cb96718f830615c8317",
      name: "Fars Province Command Council",
      previous_name: "",
      number: "U30315",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d7ebf6718f830615c832b",
      name: "Hormozgan Province Command Council",
      previous_name: "",
      number: "U54456",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d7f166718f830615c833e",
      name: "Khorasan Province Command Council",
      previous_name: "",
      number: "U52525",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d7f656718f830615c8352",
      name: "Semnan Province Command Council",
      previous_name: "",
      number: "U88625",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d7fb36718f830635c82ea",
      name: "Gilan Province Command Council",
      previous_name: "",
      number: "U39187",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d7ff86718f830635c82fc",
      name: "Mazandaran Province Command Council",
      previous_name: "",
      number: "U15309",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d80296718f830615c8365",
      name: "Bushehr Province Command Council",
      previous_name: "",
      number: "U63467",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d80966718f830635c830e",
      name: "Hamedan Province Command Council",
      previous_name: "",
      number: "U81443",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d81326718f830615c8380",
      name: "Isfahan Province Command Council",
      previous_name: "",
      number: "U01375",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d83ed6718f830635c831c",
      name: "Operations Unit",
      previous_name: "",
      number: "U01556",
      upper_unit_id: "654d65c16718f830615c825c",
      upper_unit_name: "Markazi Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d846f6718f830635c832d",
      name: "Research and Intelligence Unit",
      previous_name: "",
      number: "U26066",
      upper_unit_id: "654d65c16718f830615c825c",
      upper_unit_name: "Markazi Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d850e6718f830635c833d",
      name: "Procurement Unit",
      previous_name: "",
      number: "U16836",
      upper_unit_id: "654d65c16718f830615c825c",
      upper_unit_name: "Markazi Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d8b926718f830635c8355",
      name: "Financial and Administrative unit",
      previous_name: "",
      number: "U66343",
      upper_unit_id: "654d65c16718f830615c825c",
      upper_unit_name: "Markazi Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d8c0d6718f830635c8366",
      name: "Training unit",
      previous_name: "",
      number: "U26811",
      upper_unit_id: "654d65c16718f830615c825c",
      upper_unit_name: "Markazi Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d8c936718f830635c8374",
      name: "The Public Relations Unit",
      previous_name: "",
      number: "U87964",
      upper_unit_id: "654d65c16718f830615c825c",
      upper_unit_name: "Markazi Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d8d9a6718f830635c838a",
      name: "Unit of Operation",
      previous_name: "",
      number: "U98933",
      upper_unit_id: "654d66836718f830635c8276",
      upper_unit_name: "West Azarbaijan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d8eb96718f830615c83b6",
      name: "Research and Intelligence Unit",
      previous_name: "",
      number: "U12355",
      upper_unit_id: "654d66836718f830635c8276",
      upper_unit_name: "West Azarbaijan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d8f426718f830615c83cb",
      name: "Procurement Unit",
      previous_name: "",
      number: "U19476",
      upper_unit_id: "654d66836718f830635c8276",
      upper_unit_name: "West Azarbaijan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d8fb76718f830615c83de",
      name: "Financial and Administrative unit",
      previous_name: "",
      number: "U12579",
      upper_unit_id: "654d66836718f830635c8276",
      upper_unit_name: "West Azarbaijan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d90246718f830615c83f2",
      name: "Training Unit",
      previous_name: "",
      number: "U80154",
      upper_unit_id: "654d66836718f830635c8276",
      upper_unit_name: "West Azarbaijan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d90dc6718f830635c839d",
      name: "Public Relations",
      previous_name: "",
      number: "U43931",
      upper_unit_id: "654d66836718f830635c8276",
      upper_unit_name: "West Azarbaijan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d91876718f830635c83a7",
      name: "Operations Unit",
      previous_name: "",
      number: "U40731",
      upper_unit_id: "654d66e46718f830615c827b",
      upper_unit_name: "Command Council of East Azarbaijan Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9b9e6718f830635c83bd",
      name: "Research and Intelligence Unit",
      previous_name: "",
      number: "U31769",
      upper_unit_id: "654d66e46718f830615c827b",
      upper_unit_name: "Command Council of East Azarbaijan Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9bee6718f830635c83cb",
      name: "Procurement Unit",
      previous_name: "",
      number: "U82798",
      upper_unit_id: "654d66e46718f830615c827b",
      upper_unit_name: "Command Council of East Azarbaijan Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9c3e6718f830635c83e1",
      name: "Financial and Administrative Unit",
      previous_name: "",
      number: "U62806",
      upper_unit_id: "654d66e46718f830615c827b",
      upper_unit_name: "Command Council of East Azarbaijan Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9cbf6718f830635c83f5",
      name: "Training Unit",
      previous_name: "",
      number: "U52092",
      upper_unit_id: "654d66e46718f830615c827b",
      upper_unit_name: "Command Council of East Azarbaijan Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9d0e6718f830615c8424",
      name: "Public Relations",
      previous_name: "",
      number: "U26653",
      upper_unit_id: "654d66e46718f830615c827b",
      upper_unit_name: "Command Council of East Azarbaijan Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9d656718f830635c840b",
      name: "Operations Unit",
      previous_name: "",
      number: "U56055",
      upper_unit_id: "654d67546718f830615c8289",
      upper_unit_name: "Kurdistan Provincial Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9dc06718f830615c8440",
      name: "Unit of Research and Information",
      previous_name: "",
      number: "U69668",
      upper_unit_id: "654d67546718f830615c8289",
      upper_unit_name: "Kurdistan Provincial Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9df46718f830615c8450",
      name: "Procurement Unit",
      previous_name: "",
      number: "U02278",
      upper_unit_id: "654d67546718f830615c8289",
      upper_unit_name: "Kurdistan Provincial Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9e396718f830615c8463",
      name: "Financial and administrative unit.",
      previous_name: "",
      number: "U29534",
      upper_unit_id: "654d67546718f830615c8289",
      upper_unit_name: "Kurdistan Provincial Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9e806718f830615c8477",
      name: "Training unit",
      previous_name: "",
      number: "U00300",
      upper_unit_id: "654d67546718f830615c8289",
      upper_unit_name: "Kurdistan Provincial Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9ecc6718f830635c8414",
      name: "Public relations",
      previous_name: "",
      number: "U82112",
      upper_unit_id: "654d67546718f830615c8289",
      upper_unit_name: "Kurdistan Provincial Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9f286718f830635c8426",
      name: "Operations Unit",
      previous_name: "",
      number: "U82498",
      upper_unit_id: "654d67cc6718f830635c828c",
      upper_unit_name: "Zanjan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9f786718f830615c848c",
      name: "Unit of Research and Information",
      previous_name: "",
      number: "U04749",
      upper_unit_id: "654d67cc6718f830635c828c",
      upper_unit_name: "Zanjan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654d9fbe6718f830615c849a",
      name: "Procurement Unit",
      previous_name: "",
      number: "U96116",
      upper_unit_id: "654d67cc6718f830635c828c",
      upper_unit_name: "Zanjan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da00a6718f830615c84ae",
      name: "Financial and Administrative Unit",
      previous_name: "",
      number: "U95906",
      upper_unit_id: "654d67cc6718f830635c828c",
      upper_unit_name: "Zanjan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da0586718f830615c84c2",
      name: "Training unit",
      previous_name: "",
      number: "U35258",
      upper_unit_id: "654d67cc6718f830635c828c",
      upper_unit_name: "Zanjan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da0aa6718f830615c84cc",
      name: "Public Relations",
      previous_name: "",
      number: "U19107",
      upper_unit_id: "654d67cc6718f830635c828c",
      upper_unit_name: "Zanjan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da1206718f830615c84de",
      name: "Operations Unit",
      previous_name: "",
      number: "U48012",
      upper_unit_id: "654d68a66718f830615c82a4",
      upper_unit_name: "Command Council of Kermanshah Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da1c46718f830615c84f6",
      name: "Procurement Unit",
      previous_name: "",
      number: "U31323",
      upper_unit_id: "654d68a66718f830615c82a4",
      upper_unit_name: "Command Council of Kermanshah Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da2106718f830635c845e",
      name: "Financial and administrative unit.",
      previous_name: "",
      number: "U44578",
      upper_unit_id: "654d68a66718f830615c82a4",
      upper_unit_name: "Command Council of Kermanshah Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da2576718f830635c8472",
      name: "Training unit",
      previous_name: "",
      number: "U43291",
      upper_unit_id: "654d68a66718f830615c82a4",
      upper_unit_name: "Command Council of Kermanshah Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da2ab6718f830635c8483",
      name: "Public Relations",
      previous_name: "",
      number: "U87061",
      upper_unit_id: "654d68a66718f830615c82a4",
      upper_unit_name: "Command Council of Kermanshah Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da2fa6718f830635c8497",
      name: "Operations Unit",
      previous_name: "",
      number: "U87571",
      upper_unit_id: "654d69296718f830615c82b8",
      upper_unit_name: "Khuzestan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da3446718f830615c8510",
      name: "Unit of Research and Information.",
      previous_name: "",
      number: "U29877",
      upper_unit_id: "654d69296718f830615c82b8",
      upper_unit_name: "Khuzestan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da3a86718f830615c8521",
      name: "Procurement Unit",
      previous_name: "",
      number: "U52323",
      upper_unit_id: "654d69296718f830615c82b8",
      upper_unit_name: "Khuzestan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da3f86718f830635c84af",
      name: "Financial and Administrative unit",
      previous_name: "",
      number: "U71069",
      upper_unit_id: "654d69296718f830615c82b8",
      upper_unit_name: "Khuzestan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da4466718f830615c8539",
      name: "Training unit",
      previous_name: "",
      number: "U77447",
      upper_unit_id: "654d69296718f830615c82b8",
      upper_unit_name: "Khuzestan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654da49a6718f830615c854d",
      name: "Public Relations",
      previous_name: "",
      number: "U43441",
      upper_unit_id: "654d69296718f830615c82b8",
      upper_unit_name: "Khuzestan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654dad516718f830615c855c",
      name: "Operation Unit",
      previous_name: "",
      number: "U34119",
      upper_unit_id: "654d698d6718f830635c82a5",
      upper_unit_name: "Lorestan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654dadc06718f830615c8570",
      name: "Research and Information Unit",
      previous_name: "",
      number: "U00552",
      upper_unit_id: "654d698d6718f830635c82a5",
      upper_unit_name: "Lorestan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654dae106718f830615c8583",
      name: "Procurement Unit",
      previous_name: "",
      number: "U58340",
      upper_unit_id: "654d698d6718f830635c82a5",
      upper_unit_name: "Lorestan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654dae5d6718f830615c8595",
      name: "Financial and administrative unit",
      previous_name: "",
      number: "U55642",
      upper_unit_id: "654d698d6718f830635c82a5",
      upper_unit_name: "Lorestan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654daeae6718f830615c85ac",
      name: "Training unit",
      previous_name: "",
      number: "U45862",
      upper_unit_id: "654d698d6718f830635c82a5",
      upper_unit_name: "Lorestan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654daefd6718f830615c85bf",
      name: "Public Relations",
      previous_name: "",
      number: "U57032",
      upper_unit_id: "654d698d6718f830635c82a5",
      upper_unit_name: "Lorestan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ed7696718f830635c84c7",
      name: "Operations Unit",
      previous_name: "",
      number: "U81444",
      upper_unit_id: "654d6a096718f830635c82ba",
      upper_unit_name: "Ilam Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ed7c16718f830615c85dc",
      name: "Unit of Research and Information.",
      previous_name: "",
      number: "U21342",
      upper_unit_id: "654d6a096718f830635c82ba",
      upper_unit_name: "Ilam Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ed8286718f830635c84dd",
      name: "Procurement Unit",
      previous_name: "",
      number: "U91394",
      upper_unit_id: "654d6a096718f830635c82ba",
      upper_unit_name: "Ilam Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ed87c6718f830615c85e9",
      name: "Financial and administrative unit",
      previous_name: "",
      number: "U59599",
      upper_unit_id: "654d6a096718f830635c82ba",
      upper_unit_name: "Ilam Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ed8da6718f830615c85fd",
      name: "Training unit",
      previous_name: "",
      number: "U72318",
      upper_unit_id: "654d6a096718f830635c82ba",
      upper_unit_name: "Ilam Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ed9316718f830615c8613",
      name: "Public relations",
      previous_name: "",
      number: "U07412",
      upper_unit_id: "654d6a096718f830635c82ba",
      upper_unit_name: "Ilam Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ed9a46718f830615c8626",
      name: "Operations Unit",
      previous_name: "",
      number: "U28155",
      upper_unit_id: "654d6a8e6718f830615c82cf",
      upper_unit_name: "Command Council of Chaharmahal and Bakhtiari province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654eda026718f830635c84f9",
      name: "Research and Intelligence Unit",
      previous_name: "",
      number: "U72130",
      upper_unit_id: "654d6a8e6718f830615c82cf",
      upper_unit_name: "Command Council of Chaharmahal and Bakhtiari province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654edafe6718f830615c8634",
      name: "Procurement Unit",
      previous_name: "",
      number: "U09066",
      upper_unit_id: "654d6a8e6718f830615c82cf",
      upper_unit_name: "Command Council of Chaharmahal and Bakhtiari province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654edb736718f830615c8642",
      name: "Financial and Administrative Unit",
      previous_name: "",
      number: "U89058",
      upper_unit_id: "654d6a8e6718f830615c82cf",
      upper_unit_name: "Command Council of Chaharmahal and Bakhtiari province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654edbf76718f830635c850e",
      name: "Training unit",
      previous_name: "",
      number: "U63123",
      upper_unit_id: "654d6a8e6718f830615c82cf",
      upper_unit_name: "Command Council of Chaharmahal and Bakhtiari province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654edc856718f830615c8656",
      name: "Public Relations",
      previous_name: "",
      number: "U39476",
      upper_unit_id: "654d6a8e6718f830615c82cf",
      upper_unit_name: "Command Council of Chaharmahal and Bakhtiari province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654edd446718f830615c8669",
      name: "Operations Unit",
      previous_name: "",
      number: "U93785",
      upper_unit_id: "654d6b1c6718f830615c82d8",
      upper_unit_name: "Command Council of Kohgiluyeh and Boyer Ahmad province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ede1a6718f830635c8527",
      name: "Research and Intelligence Unit",
      previous_name: "",
      number: "U58305",
      upper_unit_id: "654d6b1c6718f830615c82d8",
      upper_unit_name: "Command Council of Kohgiluyeh and Boyer Ahmad province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654edff46718f830635c8538",
      name: "Procurement Unit",
      previous_name: "",
      number: "U03243",
      upper_unit_id: "654d6b1c6718f830615c82d8",
      upper_unit_name: "Command Council of Kohgiluyeh and Boyer Ahmad province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee0536718f830615c867e",
      name: "Financial and Administrative unit",
      previous_name: "",
      number: "U67207",
      upper_unit_id: "654d6b1c6718f830615c82d8",
      upper_unit_name: "Command Council of Kohgiluyeh and Boyer Ahmad province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee0b96718f830615c868d",
      name: "Training unit",
      previous_name: "",
      number: "U10523",
      upper_unit_id: "654d6b1c6718f830615c82d8",
      upper_unit_name: "Command Council of Kohgiluyeh and Boyer Ahmad province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee1306718f830615c869a",
      name: "Public Relations",
      previous_name: "",
      number: "U16529",
      upper_unit_id: "654d6b1c6718f830615c82d8",
      upper_unit_name: "Command Council of Kohgiluyeh and Boyer Ahmad province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee1b26718f830635c855e",
      name: "Operations Unit",
      previous_name: "",
      number: "U22130",
      upper_unit_id: "654d6bc56718f830615c82e9",
      upper_unit_name: "Command Council of Yazd Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee2366718f830615c86b7",
      name: "Unit of Research and Information",
      previous_name: "",
      number: "U93965",
      upper_unit_id: "654d6bc56718f830615c82e9",
      upper_unit_name: "Command Council of Yazd Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee2e66718f830615c86c9",
      name: "Procurement Unit",
      previous_name: "",
      number: "U33813",
      upper_unit_id: "654d6bc56718f830615c82e9",
      upper_unit_name: "Command Council of Yazd Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee33c6718f830615c86de",
      name: "Financial and Administrative unit",
      previous_name: "",
      number: "U16260",
      upper_unit_id: "654d6bc56718f830615c82e9",
      upper_unit_name: "Command Council of Yazd Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee36b6718f830615c86ee",
      name: "Training unit",
      previous_name: "",
      number: "U65990",
      upper_unit_id: "654d6bc56718f830615c82e9",
      upper_unit_name: "Command Council of Yazd Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee3ce6718f830635c8573",
      name: "Public Relations",
      previous_name: "",
      number: "U49097",
      upper_unit_id: "654d6bc56718f830615c82e9",
      upper_unit_name: "Command Council of Yazd Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee4f66718f830615c86fc",
      name: "Operations Unit",
      previous_name: "",
      number: "U62926",
      upper_unit_id: "654d6c286718f830615c82f8",
      upper_unit_name: "Kerman Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee53e6718f830635c858f",
      name: "Research and Information Unit",
      previous_name: "",
      number: "U92837",
      upper_unit_id: "654d6c286718f830615c82f8",
      upper_unit_name: "Kerman Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee57a6718f830615c870b",
      name: "Procurement Unit",
      previous_name: "",
      number: "U07801",
      upper_unit_id: "654d6c286718f830615c82f8",
      upper_unit_name: "Kerman Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee5c16718f830635c85a8",
      name: "Financial and Administrative unit",
      previous_name: "",
      number: "U14001",
      upper_unit_id: "654d6c286718f830615c82f8",
      upper_unit_name: "Kerman Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ee6f86718f830635c85b7",
      name: "Training unit",
      previous_name: "",
      number: "U04873",
      upper_unit_id: "654d6c286718f830615c82f8",
      upper_unit_name: "Kerman Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654eea2e6718f830635c85c6",
      name: "Public Relations",
      previous_name: "",
      number: "U62888",
      upper_unit_id: "654d6c286718f830615c82f8",
      upper_unit_name: "Kerman Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654eeada6718f830635c85d8",
      name: "Operations Unit",
      previous_name: "",
      number: "U38751",
      upper_unit_id: "654d6c6c6718f830615c8309",
      upper_unit_name: "Command Council of Sistan and Baluchistan Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654eeb376718f830615c871e",
      name: "Research and Information Unit",
      previous_name: "",
      number: "U39050",
      upper_unit_id: "654d6c6c6718f830615c8309",
      upper_unit_name: "Command Council of Sistan and Baluchistan Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654eeb8a6718f830635c85f5",
      name: "Procurement Unit",
      previous_name: "",
      number: "U86665",
      upper_unit_id: "654d6c6c6718f830615c8309",
      upper_unit_name: "Command Council of Sistan and Baluchistan Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654eec376718f830615c8728",
      name: "Financial and Administrative unit",
      previous_name: "",
      number: "U00868",
      upper_unit_id: "654d6c6c6718f830615c8309",
      upper_unit_name: "Command Council of Sistan and Baluchistan Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654eec796718f830615c8739",
      name: "Training unit",
      previous_name: "",
      number: "U54916",
      upper_unit_id: "654d6c6c6718f830615c8309",
      upper_unit_name: "Command Council of Sistan and Baluchistan Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654eece26718f830615c8748",
      name: "Public Relations",
      previous_name: "",
      number: "U28190",
      upper_unit_id: "654d6c6c6718f830615c8309",
      upper_unit_name: "Command Council of Sistan and Baluchistan Province",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef3bf6718f830635c8610",
      name: "Operations Unit",
      previous_name: "",
      number: "U06086",
      upper_unit_id: "654d6cb96718f830615c8317",
      upper_unit_name: "Fars Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef4106718f830615c8758",
      name: "Research and Intelligence Unit",
      previous_name: "",
      number: "U95159",
      upper_unit_id: "654d6cb96718f830615c8317",
      upper_unit_name: "Fars Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef44e6718f830635c8621",
      name: "Procurement Unit",
      previous_name: "",
      number: "U04452",
      upper_unit_id: "654d6cb96718f830615c8317",
      upper_unit_name: "Fars Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef4966718f830615c8770",
      name: "Financial and administrative unit.",
      previous_name: "",
      number: "U87787",
      upper_unit_id: "654d6cb96718f830615c8317",
      upper_unit_name: "Fars Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef4cb6718f830615c8783",
      name: "Training unit",
      previous_name: "",
      number: "U22020",
      upper_unit_id: "654d6cb96718f830615c8317",
      upper_unit_name: "Fars Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef5216718f830615c8794",
      name: "Public Relations",
      previous_name: "",
      number: "U34372",
      upper_unit_id: "654d6cb96718f830615c8317",
      upper_unit_name: "Fars Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef5666718f830635c862f",
      name: "Operations Unit",
      previous_name: "",
      number: "U40343",
      upper_unit_id: "654d7ebf6718f830615c832b",
      upper_unit_name: "Hormozgan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef5c26718f830635c8645",
      name: "Research and Information Unit",
      previous_name: "",
      number: "U55041",
      upper_unit_id: "654d7ebf6718f830615c832b",
      upper_unit_name: "Hormozgan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef6056718f830615c87a9",
      name: "Procurement Unit",
      previous_name: "",
      number: "U36580",
      upper_unit_id: "654d7ebf6718f830615c832b",
      upper_unit_name: "Hormozgan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef6436718f830635c8657",
      name: "Financial and administrative unit",
      previous_name: "",
      number: "U39025",
      upper_unit_id: "654d7ebf6718f830615c832b",
      upper_unit_name: "Hormozgan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef6866718f830615c87bb",
      name: "Training unit",
      previous_name: "",
      number: "U45937",
      upper_unit_id: "654d7ebf6718f830615c832b",
      upper_unit_name: "Hormozgan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef6fe6718f830635c866c",
      name: "Public Relations",
      previous_name: "",
      number: "U44680",
      upper_unit_id: "654d7ebf6718f830615c832b",
      upper_unit_name: "Hormozgan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef7496718f830635c867e",
      name: "Operations Unit",
      previous_name: "",
      number: "U91497",
      upper_unit_id: "654d7f166718f830615c833e",
      upper_unit_name: "Khorasan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef7876718f830635c8690",
      name: "Unit of Research and Intelligence",
      previous_name: "",
      number: "U24864",
      upper_unit_id: "654d7f166718f830615c833e",
      upper_unit_name: "Khorasan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef7c16718f830635c86a2",
      name: "Procurement Unit",
      previous_name: "",
      number: "U00197",
      upper_unit_id: "654d7f166718f830615c833e",
      upper_unit_name: "Khorasan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef8056718f830615c87c7",
      name: "Financial and Administrative Unit.",
      previous_name: "",
      number: "U33139",
      upper_unit_id: "654d7f166718f830615c833e",
      upper_unit_name: "Khorasan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef8486718f830635c86ba",
      name: "Training unit",
      previous_name: "",
      number: "U40491",
      upper_unit_id: "654d7f166718f830615c833e",
      upper_unit_name: "Khorasan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef8a46718f830635c86c9",
      name: "Public Relations",
      previous_name: "",
      number: "U39731",
      upper_unit_id: "654d7f166718f830615c833e",
      upper_unit_name: "Khorasan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef8ee6718f830635c86db",
      name: "Operations Unit",
      previous_name: "",
      number: "U40314",
      upper_unit_id: "654d7f656718f830615c8352",
      upper_unit_name: "Semnan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef9276718f830635c86ed",
      name: "Unit of Research and Information",
      previous_name: "",
      number: "U45722",
      upper_unit_id: "654d7f656718f830615c8352",
      upper_unit_name: "Semnan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef9616718f830635c86fe",
      name: "Procurement Unit",
      previous_name: "",
      number: "U04354",
      upper_unit_id: "654d7f656718f830615c8352",
      upper_unit_name: "Semnan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef9a26718f830635c8707",
      name: "Financial and administrative unit.",
      previous_name: "",
      number: "U81208",
      upper_unit_id: "654d7f656718f830615c8352",
      upper_unit_name: "Semnan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654ef9e36718f830635c8710",
      name: "Training unit",
      previous_name: "",
      number: "U28471",
      upper_unit_id: "654d7f656718f830615c8352",
      upper_unit_name: "Semnan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654efa1e6718f830615c87f3",
      name: "Public Relations",
      previous_name: "",
      number: "U41376",
      upper_unit_id: "654d7f656718f830615c8352",
      upper_unit_name: "Semnan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654efa856718f830615c8805",
      name: "Operations Unit",
      previous_name: "",
      number: "U22077",
      upper_unit_id: "654d7fb36718f830635c82ea",
      upper_unit_name: "Gilan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654efacd6718f830635c8724",
      name: "Unit of Research and Information.",
      previous_name: "",
      number: "U98742",
      upper_unit_id: "654d7fb36718f830635c82ea",
      upper_unit_name: "Gilan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654efb066718f830635c8734",
      name: "Procurement Unit",
      previous_name: "",
      number: "U46169",
      upper_unit_id: "654d7fb36718f830635c82ea",
      upper_unit_name: "Gilan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654efb3e6718f830615c8818",
      name: "Financial and Administrative Unit",
      previous_name: "",
      number: "U84878",
      upper_unit_id: "654d7fb36718f830635c82ea",
      upper_unit_name: "Gilan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654efbaa6718f830615c882f",
      name: "Training unit",
      previous_name: "",
      number: "U92860",
      upper_unit_id: "654d7fb36718f830635c82ea",
      upper_unit_name: "Gilan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654efbe66718f830615c8841",
      name: "Public relations",
      previous_name: "",
      number: "U00169",
      upper_unit_id: "654d7fb36718f830635c82ea",
      upper_unit_name: "Gilan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654efc2e6718f830635c874a",
      name: "Operations Unit",
      previous_name: "",
      number: "U91628",
      upper_unit_id: "654d7ff86718f830635c82fc",
      upper_unit_name: "Mazandaran Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "654efc656718f830615c8854",
      name: "Research and Information Unit",
      previous_name: "",
      number: "U88351",
      upper_unit_id: "654d7ff86718f830635c82fc",
      upper_unit_name: "Mazandaran Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552aff96718f830615c886a",
      name: "Procurement Unit",
      previous_name: "",
      number: "U03839",
      upper_unit_id: "654d7ff86718f830635c82fc",
      upper_unit_name: "Mazandaran Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b04c6718f830615c887f",
      name: "Financial and Administrative Unit",
      previous_name: "",
      number: "U15708",
      upper_unit_id: "654d7ff86718f830635c82fc",
      upper_unit_name: "Mazandaran Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b0896718f830615c888f",
      name: "Training unit",
      previous_name: "",
      number: "U49111",
      upper_unit_id: "654d7ff86718f830635c82fc",
      upper_unit_name: "Mazandaran Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b0bc6718f830615c889d",
      name: "Public Relations",
      previous_name: "",
      number: "U64012",
      upper_unit_id: "654d7ff86718f830635c82fc",
      upper_unit_name: "Mazandaran Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b10b6718f830635c876a",
      name: "Operations Unit",
      previous_name: "",
      number: "U76072",
      upper_unit_id: "654d80296718f830615c8365",
      upper_unit_name: "Bushehr Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b1526718f830635c877c",
      name: "Research and Intelligence Unit",
      previous_name: "",
      number: "U69240",
      upper_unit_id: "654d80296718f830615c8365",
      upper_unit_name: "Bushehr Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b18d6718f830615c88a7",
      name: "Procurement Unit",
      previous_name: "",
      number: "U10062",
      upper_unit_id: "654d80296718f830615c8365",
      upper_unit_name: "Bushehr Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b1c86718f830635c878f",
      name: "Financial and administrative unit.",
      previous_name: "",
      number: "U02267",
      upper_unit_id: "654d80296718f830615c8365",
      upper_unit_name: "Bushehr Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b20e6718f830635c87a0",
      name: "Training Unit",
      previous_name: "",
      number: "U34318",
      upper_unit_id: "654d80296718f830615c8365",
      upper_unit_name: "Bushehr Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b24a6718f830615c88be",
      name: "Public relations",
      previous_name: "",
      number: "U60496",
      upper_unit_id: "654d80296718f830615c8365",
      upper_unit_name: "Bushehr Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b2906718f830635c87af",
      name: "Operations Unit",
      previous_name: "",
      number: "U78978",
      upper_unit_id: "654d80966718f830635c830e",
      upper_unit_name: "Hamedan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b2cc6718f830635c87bb",
      name: "Research and Information Unit",
      previous_name: "",
      number: "U61928",
      upper_unit_id: "654d80966718f830635c830e",
      upper_unit_name: "Hamedan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b3136718f830635c87c9",
      name: "Procurement Unit",
      previous_name: "",
      number: "U89685",
      upper_unit_id: "654d80966718f830635c830e",
      upper_unit_name: "Hamedan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b35b6718f830635c87d8",
      name: "Financial and Administrative Unit",
      previous_name: "",
      number: "U59319",
      upper_unit_id: "654d80966718f830635c830e",
      upper_unit_name: "Hamedan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b3946718f830615c88df",
      name: "Training unit",
      previous_name: "",
      number: "U31417",
      upper_unit_id: "654d80966718f830635c830e",
      upper_unit_name: "Hamedan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b3dd6718f830615c88ee",
      name: "Public Relations",
      previous_name: "",
      number: "U30719",
      upper_unit_id: "654d80966718f830635c830e",
      upper_unit_name: "Hamedan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b4256718f830635c87e6",
      name: "Operations Unit",
      previous_name: "",
      number: "U21826",
      upper_unit_id: "654d81326718f830615c8380",
      upper_unit_name: "Isfahan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b4706718f830615c8909",
      name: "Research and Information Unit",
      previous_name: "",
      number: "U69288",
      upper_unit_id: "654d81326718f830615c8380",
      upper_unit_name: "Isfahan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b4aa6718f830615c891b",
      name: "Procurement Unit",
      previous_name: "",
      number: "U03436",
      upper_unit_id: "654d81326718f830615c8380",
      upper_unit_name: "Isfahan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b4f26718f830615c892d",
      name: "Financial and Administrative unit",
      previous_name: "",
      number: "U93361",
      upper_unit_id: "654d81326718f830615c8380",
      upper_unit_name: "Isfahan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b5306718f830615c893f",
      name: "Training unit",
      previous_name: "",
      number: "U83666",
      upper_unit_id: "654d81326718f830615c8380",
      upper_unit_name: "Isfahan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6552b56f6718f830615c8951",
      name: "Public Relations",
      previous_name: "",
      number: "U18690",
      upper_unit_id: "654d81326718f830615c8380",
      upper_unit_name: "Isfahan Province Command Council",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "6556e9486718f8861c54b9b2",
      name: "Intelligence unit",
      previous_name: "",
      number: "U42396",
      upper_unit_id: "6549b9996718f830615c80b3",
      upper_unit_name: "The Supreme Command Council.",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "06-1979",
      termination_date: null,
    },
    {
      id: "6577dfd96718f88648c65a4a",
      name: "Headquarters",
      previous_name: "",
      number: "U98321",
      upper_unit_id: "65340d2d6718f85f4dfc87a9",
      upper_unit_name: "Islamic revolution guards corps",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: "04-22-1979",
      termination_date: null,
    },
    {
      id: "658642db6718f8112432f5f2",
      name: "Command of Sayyed al-Shuhada Base",
      previous_name: "",
      number: "U01201",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "operational",
      location: "Isfahan",
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "658647276718f8112232f585",
      name: "Love surah publications",
      previous_name: "",
      number: "U38391",
      upper_unit_id: "658647dd6718f8112232f59a",
      upper_unit_name: "Amirul Momineen Ilam Corps",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "658647dd6718f8112232f59a",
      name: "Amirul Momineen Ilam Corps",
      previous_name: "",
      number: "U43686",
      upper_unit_id: "6533f8146718f85f4ffc86a2",
      upper_unit_name: "Ground Forces",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "6589ece56718f8112432f715",
      name: "Ramadan camp",
      previous_name: "",
      number: "U06513",
      upper_unit_id: "6533f8146718f85f4ffc86a2",
      upper_unit_name: "Ground Forces",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "659785236718f80bc2df6c9e",
      name: "Special Forces Commandos.",
      previous_name: "",
      number: "U54852",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65c049df6718f896914c689d",
      name: "Sepah Qamar Bani Hashem, Chaharmahal and Bakhtiari Province.",
      previous_name: "",
      number: "U09399",
      upper_unit_id: "658642db6718f8112432f5f2",
      upper_unit_name: "Command of Sayyed al-Shuhada Base",
      military_forces_type: "Ground Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d698156718f86235863b69",
      name: "Deputy Coordination Office",
      previous_name: "",
      number: "U93054",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d69a9b6718f86233863b81",
      name: "Legal Assistance",
      previous_name: "",
      number: "U26890",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d69c356718f86235863b86",
      name: "Operations Department",
      previous_name: "",
      number: "U28602",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d69ef96718f86235863b9b",
      name: "Human Resources department",
      previous_name: "",
      number: "U51935",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d6a3436718f86233863bbf",
      name: "Assistant Directorate of Military Maintenance",
      previous_name: "",
      number: "U40132",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d6a4506718f86235863bba",
      name: "Assistant Intelligence",
      previous_name: "",
      number: "U57183",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d6a6c56718f86233863bd9",
      name: "Deputy of Education and Training",
      previous_name: "",
      number: "U08678",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "educational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d6b4a16718f86235863be4",
      name: "Administration of Planning and Ground Operations",
      previous_name: "",
      number: "U48567",
      upper_unit_id: "65d69c356718f86235863b86",
      upper_unit_name: "Operations Department",
      military_forces_type: "Ground Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d7ed216718f8c5bd699e49",
      name: "Ground Force Command",
      previous_name: "",
      number: "U20512",
      upper_unit_id: "65340d2d6718f85f4dfc87a9",
      upper_unit_name: "Islamic revolution guards corps",
      military_forces_type: "Ground Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d7f0ec6718f8c5bf699e44",
      name: "Shooting and Sniper Training Department",
      previous_name: "",
      number: "U01791",
      upper_unit_id: "65d6a6c56718f86233863bd9",
      upper_unit_name: "Deputy of Education and Training",
      military_forces_type: "Ground Force",
      role: "educational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d80c336718f8c5bd699ea5",
      name: "Imam Hussein's Caravans",
      previous_name: "",
      number: "U79453",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d811d06718f8c5bd699eb8",
      name: "Yegan-e Fathin",
      previous_name: "",
      number: "U38449",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d813d96718f8c5bd699ee7",
      name: "Special Forces Saberin",
      previous_name: "",
      number: "U29218",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: "2000",
      termination_date: null,
    },
    {
      id: "65d816e46718f8c5bf699eba",
      name: "Command of drones",
      previous_name: "",
      number: "U89399",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: "2007",
      termination_date: null,
    },
    {
      id: "65d81bef6718f8c5bf699ecb",
      name: "Aviation",
      previous_name: "",
      number: "U33288",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d8203f6718f8c5bf699ee3",
      name: "Construction Base and Deprivation Alleviation",
      previous_name: "",
      number: "U74538",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d8c2aa6718f83a42e8f053",
      name: 'Special Forces unit "Abdullah" of the Navy',
      previous_name: "",
      number: "U61050",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: "Navy",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d8c30f6718f83a42e8f05a",
      name: "Command of the Second Maritime Region Noah Prophet Naval Force",
      previous_name: "",
      number: "U67320",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: "Navy",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d8c4296718f83a40e8f045",
      name: "Third naval region command of Imam Hussein",
      previous_name: "",
      number: "U95854",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d8c5426718f83a42e8f07e",
      name: "The Fourth Naval Command of the IRGC",
      previous_name: "",
      number: "U33244",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d8c6f26718f83a40e8f06d",
      name: "Fifth Regional Command of Imam Baqir, IRGC Navy",
      previous_name: "",
      number: "U50361",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d8d69a6718f83a42e8f0ef",
      name: "Commander of the Missile Zone, Salman Asif",
      previous_name: "",
      number: "U51480",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d8d7ef6718f83a40e8f0c5",
      name: "Imam Ali Naval Base Chabahar",
      previous_name: "",
      number: "U28880",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d8d9076718f83a40e8f0ce",
      name: "Naval Base Imamate Jask",
      previous_name: "",
      number: "U25446",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d8d9de6718f83a40e8f0e9",
      name: "Samin al-Hujjaj Base Babelsar",
      previous_name: "",
      number: "U23725",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d8daef6718f83a40e8f101",
      name: "Naval Base Arvand",
      previous_name: "",
      number: "U11725",
      upper_unit_id: "65d8c4296718f83a40e8f045",
      upper_unit_name: "Third naval region command of Imam Hussein",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65da4f5a6718f83a40e8f112",
      name: "Amiral Momenin University of Isfahan",
      previous_name: "",
      number: "U70298",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "educational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65da50ce6718f83a40e8f123",
      name: "Self-sufficiency Jihad",
      previous_name: "",
      number: "U28006",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65da53e56718f83a40e8f13d",
      name: "Command of the Quds Southeast Base",
      previous_name: "",
      number: "U32722",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65da5c716718f83a42e8f1b6",
      name: "Assistant Coordinator",
      previous_name: "",
      number: "U96043",
      upper_unit_id: "65da53e56718f83a40e8f13d",
      upper_unit_name: "Command of the Quds Southeast Base",
      military_forces_type: "Ground Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65db2c8e6718f83a42e8f2bb",
      name: "IRGC Development and Reconstruction Headquarters",
      previous_name: "",
      number: "U89537",
      upper_unit_id: "6506f8e76718f86efa48270e",
      upper_unit_name: "Command of Quds Force",
      military_forces_type: "Quds Force",
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65db40bd6718f83a42e8f325",
      name: "Operations Deputy",
      previous_name: "",
      number: "U57664",
      upper_unit_id: "6506f8e76718f86efa48270e",
      upper_unit_name: "Command of Quds Force",
      military_forces_type: "Quds Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65db9a376718f83a42e8f341",
      name: "Political Deputy",
      previous_name: "",
      number: "U58483",
      upper_unit_id: "6506f8e76718f86efa48270e",
      upper_unit_name: "Command of Quds Force",
      military_forces_type: "Quds Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65db9b966718f83a42e8f350",
      name: "Representative of the Supreme Leader",
      previous_name: "",
      number: "U50605",
      upper_unit_id: "6506f8e76718f86efa48270e",
      upper_unit_name: "Command of Quds Force",
      military_forces_type: "Quds Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dbe31c6718f83a40e8f3ed",
      name: "Command of the Medina Base",
      previous_name: "",
      number: "U28644",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dbe8086718f83a40e8f417",
      name: "Deputy Coordinator",
      previous_name: "",
      number: "U62183",
      upper_unit_id: "65dbe31c6718f83a40e8f3ed",
      upper_unit_name: "Command of the Medina Base",
      military_forces_type: "Ground Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dc39f76718f83a42e8f4de",
      name: "Organization for Research and Self-Sufficiency Jihad of the Navy",
      previous_name: "",
      number: "U34128",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: "Navy",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dc3b266718f83a42e8f4fe",
      name: "Khamenei University, IRGC Naval force",
      previous_name: "",
      number: "U33343",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: "Navy",
      role: "educational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dc3e9f6718f83a40e8f4fb",
      name: "IRGC Naval Resistant Economy and Disadvantaged Removal Base",
      previous_name: "",
      number: "U23162",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: "Navy",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dc4fb36718f83a42e8f59a",
      name: "The commander of the Naval IRGC",
      previous_name: "",
      number: "U68157",
      upper_unit_id: "651bea036718f852b54eb677",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: "Navy",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dc5a366718f83a42e8f5c2",
      name: "Hospital Hazrat Qaem Naval Forces",
      previous_name: "",
      number: "U81286",
      upper_unit_id: "65dc4fb36718f83a42e8f59a",
      upper_unit_name: "The commander of the Naval IRGC",
      military_forces_type: "Navy",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dc633f6718f83a42e8f5e5",
      name: "Muslimin Hospital Shiraz",
      previous_name: "",
      number: "U71569",
      upper_unit_id: "65dc4fb36718f83a42e8f59a",
      upper_unit_name: "The commander of the Naval IRGC",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dc636b6718f83a40e8f58e",
      name: "Imam Zamani Hospital Bandar Abbas",
      previous_name: "",
      number: "U55430",
      upper_unit_id: "65dc4fb36718f83a42e8f59a",
      upper_unit_name: "The commander of the Naval IRGC",
      military_forces_type: "Navy",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dc63fd6718f83a40e8f598",
      name: "Imam Reza Hospital, Chalus",
      previous_name: "",
      number: "U47696",
      upper_unit_id: "65dc4fb36718f83a42e8f59a",
      upper_unit_name: "The commander of the Naval IRGC",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dc647f6718f83a42e8f5f9",
      name: "Martyr Mohammad hospital Bandar Lengeh",
      previous_name: "",
      number: "U91427",
      upper_unit_id: "65dc4fb36718f83a42e8f59a",
      upper_unit_name: "The commander of the Naval IRGC",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dc64dc6718f83a42e8f606",
      name: "Soleimani Hospital, Ahvaz",
      previous_name: "",
      number: "U55305",
      upper_unit_id: "65dc4fb36718f83a42e8f59a",
      upper_unit_name: "The commander of the Naval IRGC",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dc656a6718f83a40e8f5ad",
      name: "Seyed al-Shohada Clinic Chabahar",
      previous_name: "",
      number: "U09910",
      upper_unit_id: "65dc4fb36718f83a42e8f59a",
      upper_unit_name: "The commander of the Naval IRGC",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dc66cf6718f83a42e8f60f",
      name: "Amui Mahshahr Medical Complex",
      previous_name: "",
      number: "U74540",
      upper_unit_id: "65dc4fb36718f83a42e8f59a",
      upper_unit_name: "The commander of the Naval IRGC",
      military_forces_type: null,
      role: null,
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dcff876718f83a40e8f5c9",
      name: "Ramadan base",
      previous_name: "",
      number: "U47768",
      upper_unit_id: "6506f8e76718f86efa48270e",
      upper_unit_name: "Command of Quds Force",
      military_forces_type: "Quds Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65dd01486718f83a42e8f636",
      name: "Deputy Support Command",
      previous_name: "",
      number: "U26054",
      upper_unit_id: "6506f8e76718f86efa48270e",
      upper_unit_name: "Command of Quds Force",
      military_forces_type: "Quds Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65e0556c6718f86ec9734820",
      name: "Economic Deputy",
      previous_name: "",
      number: "U48846",
      upper_unit_id: "6506f8e76718f86efa48270e",
      upper_unit_name: "Command of Quds Force",
      military_forces_type: "Quds Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65e0dcfd6718f8dc7242abba",
      name: "Command of the Samen al-aemmeh Base",
      previous_name: "",
      number: "U48485",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "operational",
      location: "Mashhad",
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65e0f8876718f8dc7042ac16",
      name: "Deputy Coordinator",
      previous_name: "",
      number: "U51988",
      upper_unit_id: "65e0dcfd6718f8dc7242abba",
      upper_unit_name: "Command of the Samen al-aemmeh Base",
      military_forces_type: "Ground Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65e3cf8c6718f842cdded9dc",
      name: "Deputy Coordinator",
      previous_name: "",
      number: "U25511",
      upper_unit_id: "65c049df6718f896914c689d",
      upper_unit_name:
        "Sepah Qamar Bani Hashem, Chaharmahal and Bakhtiari Province.",
      military_forces_type: "Ground Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65e3d0c46718f842cdded9fd",
      name: "Unit 44 Qamar Bani Hashim",
      previous_name: "",
      number: "U28343",
      upper_unit_id: "65c049df6718f896914c689d",
      upper_unit_name:
        "Sepah Qamar Bani Hashem, Chaharmahal and Bakhtiari Province.",
      military_forces_type: "Ground Force",
      role: "operational",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65e3d5636718f842cddeda41",
      name: "Deputy Coordinator",
      previous_name: "",
      number: "U25356",
      upper_unit_id: "651527af6718f87c0e83e320",
      upper_unit_name: "Command of Karbala Garrison",
      military_forces_type: "Ground Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65e4e0626718f842cfdeda43",
      name: "Deputy Coordinator",
      previous_name: "",
      number: "U27491",
      upper_unit_id: "653132586718f8d05ee67c0b",
      upper_unit_name:
        "IRGC is the holy Abu al-Fadl division of Luristan province",
      military_forces_type: "Ground Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65e4e4a66718f842cddeda7e",
      name: "Cultural Deputy",
      previous_name: "",
      number: "U06724",
      upper_unit_id: "6506f8e76718f86efa48270e",
      upper_unit_name: "Command of Quds Force",
      military_forces_type: "Quds Force",
      role: "administrative",
      location: null,
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65e4ebf76718f842cfdeda7e",
      name: "IRGC Type 57 Imam Abu al-Fadl",
      previous_name: "",
      number: "U55751",
      upper_unit_id: "653132586718f8d05ee67c0b",
      upper_unit_name:
        "IRGC is the holy Abu al-Fadl division of Luristan province",
      military_forces_type: "Ground Force",
      role: "operational",
      location: "Lorestan",
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65e4ed6f6718f842cddedac9",
      name: "Advanced Warfare 24 Bases",
      previous_name: "",
      number: "U41505",
      upper_unit_id: "653132586718f8d05ee67c0b",
      upper_unit_name:
        "IRGC is the holy Abu al-Fadl division of Luristan province",
      military_forces_type: "Ground Force",
      role: "operational",
      location: "Borujerd",
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65e684286718f87d746fcbc5",
      name: "Command of Tharallah Garrison",
      previous_name: "",
      number: "U75194",
      upper_unit_id: "65d7ed216718f8c5bd699e49",
      upper_unit_name: "Ground Force Command",
      military_forces_type: "Ground Force",
      role: "administrative",
      location: "Tehran",
      territory: null,
      creation_date: null,
      termination_date: null,
    },
    {
      id: "65d8c2aa6718f83a42e8f054",
      name: 'Child Special Forces unit "Abdullah" of the Navy',
      previous_name: "PP",
      number: "U61051",
      upper_unit_id: "65d8c2aa6718f83a42e8f053",
      upper_unit_name: "Command of the Navy force",
      military_forces_type: "Navy",
      role: "operational",
      location: "Earth",
      territory: null,
      creation_date: "03-12-2024",
      termination_date: null,
    },
    {
      id: "65340d2d6718f85f4dfc87b9",
      name: "Islamic revolution guards corps",
      previous_name: "Previous",
      number: "U49411",
      upper_unit_id: "65340d2d6718f85f4dfc87a9",
      upper_unit_name: "Islamic revolution guards corps",
      // military_forces_type: null,
      military_forces_type: "Ground Force",
      role: "operational",
      location: "Earth",
      territory: "Land",
      creation_date: "03-12-2024",
      termination_date: null,
    },
  ],
  success: true,
  error_message: null,
};
