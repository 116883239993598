import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BodyWrapper from "../commons/bodyWrapper";
import LanguageDropdown from "./languageDropdown";
import NavBar from "../homeNavbar";
import "./header.css";

const Header = () => {
  const { t } = useTranslation("translation");
  const [openMenu, setOpenMenu] = useState(false);
  const handleMenu = () => setOpenMenu(!openMenu);
  const preventClose = (e) => e.stopPropagation();

  return (
    <div>
      <div className="headerWrapper">
        <BodyWrapper>
          <div className="headerWrapper__content">
            <h1 className="headerWrapper__left">
              <img
                className="headerWrapper__logo hideOnMobileTablet"
                src="/images/commons/logo.png"
                alt="logo"
              />
              <img
                className="headerWrapper__logo hideOnDesktop"
                src="/images/commons/logo.png"
                alt="logo"
              />
              <div className="headerWrapper__info">
                <div className="headerWrapper__name">{t("header.name")}</div>
                <div className="headerWrapper__slogan">
                  {t("header.slogan")}
                </div>
              </div>
            </h1>

            <div className="headerWrapper__right flexRow">
              <LanguageDropdown />
              <div
                className="headerWrapper__menu--mobile bgWhieColorGreen hideOnDesktop"
                onClick={handleMenu}
              >
                <img
                  src="/images/commons/icon-menu.png"
                  alt="menu"
                  className="headerWrapper__menu--mobile__icon"
                />
                <div className="headerWrapper__menu--mobile__text">
                  {t("header.menu")}
                </div>
              </div>
            </div>
          </div>
        </BodyWrapper>
      </div>

      {openMenu && (
        <div
          className="headerWrapper__menuOverlay hideOnDesktop"
          onClick={handleMenu}
        >
          <div onClick={preventClose}>
            <NavBar />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
