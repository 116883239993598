import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { head } from "lodash";

import { ILLICIT_ACTIVITY } from "../../../commons/data/allData";
import Activity from "./activity";
import Title from "./title";
import "./violations.css";

const Violations = () => {
  const { t } = useTranslation("translation");

  const firstItem = head(ILLICIT_ACTIVITY)?.name;
  const [tab, setTab] = useState(firstItem);
  const handleSetTab = (tab) => {
    setTab(tab);
  };

  return (
    <div className="sectionContainer">
      <div className="sectionName">{t("home.body.activities.name")}</div>
      <div className="activityContainer">
        <Title setTab={handleSetTab} />
        <Activity tabName={tab} />
      </div>
    </div>
  );
};

export default Violations;
