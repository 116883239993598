import React from "react";
import DatePicker from "react-datepicker";
import "./chartDatePicker.css";
import "react-datepicker/dist/react-datepicker.css";

const ChartDatePicker = ({ activeDate, setActiveDate }) => {
  return (
    <div className="chartDatePicker__wrapper">
      <div className="bgWhiteFullAbsolute"></div>

      <div className="chartDatePicker">
        <DatePicker
          selected={activeDate}
          onChange={(date) => setActiveDate(date)}
          showYearDropdown
          showMonthDropdown
          dateFormat="yyyy/MM/dd"
          dateFormatCalendar=" "
          yearDropdownItemNumber={60}
          minDate={new Date("04-22-1979")}
          maxDate={new Date()}
          scrollableYearDropdown
          className="chartDatePicker__input inputBgBorder"
          calendarClassName="chartDatePicker__calendar"
        />
        <img
          src="/images/commons/icon-calendar.png"
          alt="calendar icon"
          className="chartDatePicker__icon"
        />
      </div>
    </div>
  );
};

export default ChartDatePicker;
