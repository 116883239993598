import React, { useState } from "react";
import { map } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import { ILLICIT_ACTIVITY } from "../../../../commons/data/allData";

const Title = ({ setTab }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation("translation");

  return (
    <div className="titleContainer">
      {map(ILLICIT_ACTIVITY, ({ imgSrc, name }, index) => {
        const handleActive = () => {
          setActiveIndex(index);
          setTab(name);
        };
        const isActive =
          activeIndex === index ? "informationContainer--active" : "";

        return (
          <div
            key={uuidv4()}
            onClick={handleActive}
            className={`informationContainer ${isActive}`}
          >
            <img className="icon " src={imgSrc} alt="icon" />
            <span className="inforName">
              {t(`home.body.activities.tabs.${name}`)}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default Title;
