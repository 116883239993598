import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./lineGraph.css";
import LineGraphModel from "./lineGraphModel";
import DateDropdown from "./dateDropdown";

const LineGraph = () => {
  const { t } = useTranslation("translation");
  const [duration, setDuration] = useState("Daily");
  const [zoomOut, setZoomOut] = useState(false);
  const handleZoom = () => setZoomOut(!zoomOut);
  const zoomCN = zoomOut ? "graphContainer--zoomOut" : "";

  return (
    <div className={`sectionContainer`}>
      <div className="lineGraph__sectionName">
        {t("home.body.lineGraph.title")}
      </div>
      <div className={`${zoomCN} graphContainer`}>
        <div className={`${zoomCN} wrapper`}>
          <LineGraphModel duration={duration} zoomOut={zoomOut} />
          <DateDropdown setDuration={setDuration} />
          <div className="linesChartZoomIcon" onClick={handleZoom}>
            <img
              className="chartZoomIcon__image"
              src="/images/commons/icon-zoom-2.png"
              alt="zoom"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineGraph;
