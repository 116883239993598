import { ReactComponent as ReactLogo } from "../../../assets/images/loading.svg";
import "./loading.css";

const Loading = () => {
  return (
    <div className="loading">
      <ReactLogo />
    </div>
  );
};
export default Loading;
