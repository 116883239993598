import React from "react";
import { v4 as uuidv4 } from "uuid";
import { find, map } from "lodash";
import { ICON_TAB_ALL, ICON_TAB_OTHER } from "../../../../commons/data/allData";
import "./chartTabs.css";

const ChartTabs = ({ list, typeId, setTypeId }) => {
  const activeTab = find(list, { id: typeId });
  const handleClick = (tabId) => {
    setTypeId(tabId);
  };

  return (
    <div className="chartTabs">
      {map(list, (tab, id) => {
        const { id: tabId, name: tabName } = tab;
        const isActive = tabId === activeTab?.id;
        const activeCN = isActive ? "chartTabs__item--active" : "";
        const iconSrc = !id ? ICON_TAB_ALL : ICON_TAB_OTHER;
        return (
          <div
            key={uuidv4()}
            className={`chartTabs__item ${activeCN}`}
            onClick={() => handleClick(tabId)}
          >
            <img className="chartTabs__item--icon" src={iconSrc} alt="tab" />
            <div className="chartTabs__item--text">{tabName}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ChartTabs;
