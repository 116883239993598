import React from "react";
import "./welcome.css";
import { useTranslation } from "react-i18next";

const Welcome = () => {
  const { t } = useTranslation("translation");
  const firstHook = (
    <p className="hook">
      {t("home.body.welcome.firstHook.welcome")}{" "}
      <span className="projectName">
        {t("home.body.welcome.firstHook.projectName")}
      </span>{" "}
      - {t("home.body.welcome.firstHook.projectDescription")}
    </p>
  );

  return (
    <div className="container">
      <div className="contentContainer">
        <div className="logoContainer">
          <img
            className="welcom__logo"
            src="/images/commons/logo.png"
            alt="welcome"
          />
          <img
            className="welcom__logo"
            src="/images/commons/logo-2.png"
            alt="welcome"
          />
        </div>
        <div className="hookContainer">
          <span>{firstHook}</span>
          <span className="hook">{t("home.body.welcome.secondHook")}</span>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
