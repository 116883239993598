import React from "react";
import { v4 as uuidv4 } from "uuid";
import { map } from "lodash";
import { PUBLICATION_BUTTONS } from "../../../commons/data/allData";
import "./publication.css";

const Publication = ({ imgSrc, name, description, author, date, active }) => {
  const bolderBorder = active ? "bolder" : "";
  return (
    <div className={`publicationContainer`}>
      <div className="insideContent">
        <div className="imgContainer">
          <img className="pubplicationImg" src={imgSrc} alt="pulication" />
        </div>
        <div className="bodyContainer">
          <div className="nameContainer">{name}</div>
          <div className="descriptionContainer">{description}</div>
          <div className="authorContainer">By: {author}</div>
          <div className="footerContainer">
            <div className="btnContainer">
              {map(PUBLICATION_BUTTONS, (button) => (
                <button
                  key={uuidv4()}
                  className="publicationContainer__btn inputBgBorder"
                >
                  {button}
                </button>
              ))}
            </div>
            <div className="date">{date}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publication;
