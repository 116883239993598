import React from "react";
import "./signupForm.css";
import { useTranslation } from "react-i18next";

const SignupForm = () => {
  const { t } = useTranslation("translation");

  return (
    <div className="sectionContainer">
      <div className="signUpBox">
        <div className="stayUpToDateContainer">
          <span className="stayUpToDateText">
            {t("home.body.signUp.title")}
          </span>
          <span className="stayUpToDateHook">{t("home.body.signUp.hook")}</span>
        </div>
        <div className="signUpContent">
          <input
            placeholder={t("home.body.signUp.firstName")}
            className="inputBox"
            type="text"
          />
          <input
            placeholder={t("home.body.signUp.lastName")}
            className="inputBox"
            type="text"
          />
          <input
            placeholder={t("home.body.signUp.eMail")}
            className="inputBox"
            type="text"
          />
          <button className="signUpButton">
            {t("home.body.signUp.signUpBtn")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
