import React from "react";
import { concat, includes, without } from "lodash";
import "./chartSortByItem.css";

const ChartSortByItem = ({ text, selectedOptions, setSelectedOptions }) => {
  const isActive = includes(selectedOptions, text);
  const activeCN = isActive ? "chartSortByItem--checked" : "";
  const handleClick = () => {
    const newOptions = isActive
      ? without(selectedOptions, text)
      : concat(selectedOptions, text);
    setSelectedOptions(newOptions);
  };

  return (
    <div className={`chartSortByItem ${activeCN}`} onClick={handleClick}>
      <div className="chartSortByItem__checkbox"></div>
      <div className="chartSortByItem__text">{text}</div>
    </div>
  );
};

export default ChartSortByItem;
