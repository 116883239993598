import React from "react";
import QuantityLine from "./quantityLine";
import "./documentCard.css";

const DocumentCard = ({ name, quantity = 0, iconSrc }) => {
  return (
    <div className="documentCard">
      <QuantityLine quantity={quantity} />
      <div className="documentCard__info">
        <img className="documentCard__icon" src={iconSrc} alt="document icon" />
        <div className="documentCard__name">{name}</div>
      </div>
    </div>
  );
};

export default DocumentCard;
