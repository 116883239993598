import "./styles/App.css";
import "./styles/variables.css";
import Header from "./components/homeHeader";
import NavBar from "./components/homeNavbar";
import Body from "./components/homeBody";
import Footer from "./components/homeFooter";

const App = (props) => {
  return (
    <div>
      <Header />
      <div className="hideOnMobileTablet">
        <NavBar />
      </div>
      <Body />
      <Footer />
      {/* <OrgChart /> */}
    </div>
  );
};

export default App;
